import { useState, useEffect, useCallback } from "react";
import axios from "axios";

export const useGPT = (prompt) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_GPT_URL;

  const askGPT = useCallback(async (prompt, token) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/ask-gpt`,
        { prompt },
        { cancelToken: token }
      );
      return response.data.text;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Axios request cancelled:", error.message);
      } else {
        throw new Error("Error communicating with backend or OpenAI.");
      }
    }
  }, [BASE_URL]);

  useEffect(() => {
    // Create a new CancelToken source for this effect
    const cancelTokenSource = axios.CancelToken.source();

    if (prompt) {
      const fetchGPTResponse = async () => {
        setLoading(true);
        setResponse(null);
        setError(null);

        try {
          const gptData = await askGPT(prompt, cancelTokenSource.token);
          setResponse(gptData);
        } catch (err) {
          console.error("Error fetching from GPT API:", err);
          setError(err.message || "Error fetching GPT response.");
        } finally {
          setLoading(false);
        }
      };

      fetchGPTResponse();
    }

    return () => {
      cancelTokenSource.cancel("Component got unmounted or prompt changed");
    };
  }, [prompt, askGPT]);

  return { loading, response, error };
};