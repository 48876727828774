import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";

const AboutUs = () => {
  return (
    <Container className="about-us-container mt-5 ">
      <Row>
        <Col className="text-center" md={{ span: 8, offset: 2 }}>
          <h1>StockEZe - Simplifying Stock Analysis</h1>
        </Col>
      </Row>

      <Row className="mt-4 ">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Our Vision</h2>
          <p>
            In an age of information overload, we saw a need for clarity - a
            streamlined way for investors to make informed decisions without
            sifting through mountains of data. StockEZe was born out of a vision
            to simplify stock analysis and help both newbie investors and
            seasoned traders make better investment decisions.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>What We Do</h2>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>Comprehensive Stock Search</strong>: Just input the stock
              symbol, and let us pull up everything you need to know - from
              company details to critical metrics.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>AI-Powered Analysis</strong>: Forget complicated charts
              and jargons. Our AI-driven engine reviews key stock metrics and
              generates a comprehensive analysis, presenting you with an easily
              digestible insight into the stock’s potential.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Intuitive Rating System</strong>: No more guesswork! Based
              on our AI’s analysis, each stock is rated on a scale of 1 to 10. A
              score of 1 suggests that the stock may not be an optimal choice
              for investment, while a perfect 10 means you might want to look
              into it right away!
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Periodic Blogs & Insights</strong>: Stay updated with the
              financial world through our regularly updated blogs. Whether it’s
              the dynamic realm of cryptocurrencies or evergreen financial
              advice, our experts have got you covered.
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Why StockEZe?</h2>
          <p>
            <strong>Simplicity Meets Accuracy</strong>: While the stock market
            is complex, understanding it shouldn’t be. We’ve eliminated the
            clutter and present you with simple, accurate, and actionable
            insights.
          </p>
          <p>
            <strong>Empowering You</strong>: Whether you're a novice stepping
            into the world of investment or a professional trader, StockEZe
            empowers you with the right tools and information to make confident
            decisions.
          </p>
          <p>
            <strong>Trust & Integrity</strong>: At StockEZe, transparency is our
            bedrock. Our AI-driven analyses are unbiased, based purely on data
            and well-established algorithms.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Join Us on this Journey</h2>
          <p>
            As we venture deeper into the financial realm, we invite you to be
            part of our community. Engage with our content, use our tools, and
            let’s simplify stock investment, one search at a time.
          </p>
          <p>Welcome to smarter investing. Welcome to StockEZe!</p>
          <hr />
          <p className="text-muted">
            Disclaimer: The information provided on StockEZe, including any
            analysis, ratings, or insights, is not financial advice. Always
            conduct your own research and consult with a financial professional
            before making any investment decisions.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
