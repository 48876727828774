import React from "react";
import ReactGA from "react-ga";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Privacy from "./pages/PrivatePolicy";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import BlogPostPage from "./components/mainBlogArticles";

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import { useEffect } from "react";

function App() {
  return (
    <div>
      <Router>
        <AnalyticsTracker />
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/blog/:id" element={<BlogPostPage />} />
        </Routes>
        <Footer />
      </Router>
      
    </div>
  );
}

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return null;
}

export default App;
