import { useState, useEffect, useRef } from "react";
import Axios from "axios";

export const useStockData = (symbol) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const cancelTokenSource = useRef(Axios.CancelToken.source());
  const BASE_URL = process.env.REACT_APP_API_IEX_URL;
  

  useEffect(() => {
    const currentTokenSource = cancelTokenSource.current; // Create a local variable
    const fetchData = async () => {
      // Reset the error state at the beginning of each fetch
      setError(null);
      try {
        const response = await Axios.get(
          `${BASE_URL}/api/iex/${symbol}`,
          {
            cancelToken: cancelTokenSource.token,
          }
        );

        const { stats, logo, company } = response.data;
        if (!stats || !stats.length || !company || !company.length) {
          throw new Error("Received data is not in the expected format.");
        }

        // Destructuring data
        const {
          grossProfit,
          week52low,
          week52high,
          nextEarningsDate,
          marketcap,
          EBITDA,
          peRatio,
          priceToSales,
          pegRatio,
          debtToEquity,
          beta,
          totalRevenue,
          priceToBook,
        } = stats[0];

        const {
          companyName,
          industry,
          shortDescription,
          ceo,
          employees,
          website,
          exchange,
        } = company[0];

        const logoUrl = logo.url;

        // Process and set your data here...
        const processedData = {
          grossProfit,
          nextEarningsDate,
          marketcap,
          companyName,
          industry,
          shortDescription,
          ceo,
          employees,
          website,
          exchange,
          logoUrl,
          week52low,
          week52high,
          EBITDA,
          peRatio,
          priceToSales,
          pegRatio,
          debtToEquity,
          beta,
          totalRevenue,
          priceToBook,
        };

        setData(processedData);
        setLoading(false);
      } catch (err) {
        if (Axios.isCancel(err)) {
          console.log("Axios request cancelled:", err.message);
        } else {
          console.error("Error fetching data from the server: ", err);
          setError(
            err.message || "Error fetching data. Please try again later."
          );
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      // Cleanup: cancel any ongoing Axios request
      currentTokenSource.cancel("Component got unmounted or symbol changed");
    };
  }, [symbol, BASE_URL]);

  return { loading, data, error };
};