import React, { useState } from "react";
import { Form, Container, InputGroup, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchDollar } from "@fortawesome/free-solid-svg-icons";
import StockWindow from "./stockWindow";
import "../styles/searchForm.css";

const DEFAULT_SYMBOL = "GOOGL";

function SearchForm() {
  const [validated, setValidated] = useState(false);
  const [stockSymbol, setStockSymbol] = useState(""); // reintroduce this state
  const [finalSymbol, setFinalSymbol] = useState(DEFAULT_SYMBOL);
  const [submitted, setSubmitted] = useState(true);

  const handleChange = (event) => {
    setStockSymbol(event.target.value); // Update stockSymbol state, not finalSymbol
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (stockSymbol.trim()) {
      setFinalSymbol(stockSymbol); // Update finalSymbol on form submission
      setValidated(true);
      setSubmitted(true);
    } else {
      setValidated(false);
    }
  };

  return (
    <Container>
      {submitted && <StockWindow symbol={finalSymbol} />}{" "}
      {/* Conditional rendering based on the submitted state */}
      <Form
        className="searchMargin"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Row>
          <Form.Group as={Col} controlId="validationCustomUsername">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faSearchDollar} size="sm" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Enter stock symbol"
                aria-describedby="inputGroupPrepend"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a stock symbol.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Button variant="info" type="submit" block>
          Submit
        </Button>
      </Form>
    </Container>
  );
}

export default SearchForm;
