import React, { useState, useEffect } from "react";
import { getBlogPosts } from "../contentfulService";
import { Container, Row, Col, Card, ListGroup, Media } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "../styles/blogPages.css";

function BlogPostsComponent() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const data = await getBlogPosts();
      setPosts(data);
    };

    fetchPosts();
  }, []);

  const blogOfTheDay = posts[0];
  const otherBlogs = posts.slice(1);

  return (
    <Container style={{ marginBottom: "30px" }}>
      <Row>
        <Col md={8}>
          {blogOfTheDay && (
            <Card className="mb-5" border="0">
              <Link to={`/blog/${blogOfTheDay.sys.id}`} style={{ textDecoration: "none", color: "inherit" }}>
                <Card.Img
                  variant="top"
                  src={blogOfTheDay.fields.featuredImage.fields.file.url}
                  alt={blogOfTheDay.fields.title}
                />
                <Card.Body>
                  <Card.Title style={{ fontWeight: "bold" }}>
                    {blogOfTheDay.fields.title}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {blogOfTheDay.fields.author.fields.name}
                  </Card.Subtitle>
                  <Card.Text>{blogOfTheDay.fields.shortDescription}</Card.Text>
                </Card.Body>
              </Link>
            </Card>
          )}
        </Col>
        <Col md={4}>
          <h4>Featured Blogs</h4>
          <ListGroup variant="flush">
            {otherBlogs.map((post) => (
              <ListGroup.Item
                key={post.sys.id}
                action
                as={Link}
                to={`/blog/${post.sys.id}`}
                className={`list-group-item-action border-0 ${styles.activeLink}`}
              >
                <Media>
                  <img
                    width={64}
                    height={64}
                    className="mr-3"
                    src={post.fields.featuredImage.fields.file.url}
                    alt={post.fields.title}
                  />
                  <Media.Body>
                    <strong>{post.fields.title}</strong>
                    <br />
                    <small className="text-muted">
                      {post.fields.author.fields.name}
                    </small>
                  </Media.Body>
                </Media>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default BlogPostsComponent;
