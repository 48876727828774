import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Tabs, Tab, Row, Col, Spinner } from "react-bootstrap";
import "../styles/tabContent.css";

function DetailCol({ label, value }) {
  return (
    <Col className="mb-2" sm={6} md={3} lg={3} xl={3} xs={6}>
      <span className="span mr-1">{label}:</span> {value}
    </Col>
  );
}

function ControlledTabs({ data, response, gptLoading }) {
  const [key, setKey] = useState("home");

  const companyDetails = [
    { label: "Website", value: data.website },
    { label: "Industry", value: data.industry },
    { label: "Exchange", value: data.exchange },
    { label: "CEO", value: data.ceo },
  ];

  const metricDetails = [
    { label: "52-week high", value: data.week52high },
    { label: "53-week low", value: data.week52low },
    { label: "P/E ratio", value: data.peRatio },
    { label: "PEG ration", value: data.pegRatio },
    { label: "Price-to-sales", value: data.priceToSales },
    { label: "Debt-to-equity", value: data.debtToEquity },
    { label: "Price-to-Book", value: data.priceToBook },
    { label: "Beta", value: data.beta },
    { label: "EBITDA", value: data.EBITDA },
    { label: "Gross profit", value: data.grossProfit },
    { label: "Market cap", value: data.marketcap },
    { label: "Next earnings", value: data.nextEarningsDate },
  ];

  return (
    <div className="border1 mb-3">
      <Tabs
        className="tabs"
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab className="tab" eventKey="home" title="Company">
          <Row className="mt-3">
            {companyDetails.map((detail) => (
              <DetailCol
                key={detail.label}
                label={detail.label}
                value={detail.value}
              />
            ))}
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={12} lg={12} xl={12} xs={12}>
              {data.shortDescription}
            </Col>
          </Row>
        </Tab>
        <Tab className="tab" eventKey="metrics" title="Metrics">
          <Row className="mt-3">
            {metricDetails.map((detail) => (
              <DetailCol
                key={detail.label}
                label={detail.label}
                value={detail.value}
              />
            ))}
          </Row>
        </Tab>
        <Tab className="tab" eventKey="aiAnalysis" title="AI Analysis">
          <Row className="mt-3">
            <Col sm={12} md={12} lg={12} xl={12} xs={12}>
              {gptLoading ? (
                <div className="text-center mt-3">
                  <p>Fetching AI analysis...</p>
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : response ? (
                <ReactMarkdown>{response}</ReactMarkdown>
              ) : (
                "No AI analysis available."
              )}
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </div>
  );
}

export default ControlledTabs;
