import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/footer.css";

const FooterPage = () => {
  return (
    <Navbar bg="dark" className="font-small mt-4 bottom">
      <Container fluid className="d-flex justify-content-between align-items-center">
        
          {/* Copyright Text */}
          <div className="color2">
            &copy; {new Date().getFullYear()} Copyright:{" "}
            <span className="color"> StockzBot </span>
          </div>
            
          {/* Privacy Policy Link */}
          <Link to="/privacy-Policy" className="footer-link">
            Privacy Policy
          </Link>
        
      </Container>
    </Navbar>
  );
};

export default FooterPage;
