import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col className="text-center" md={{ span: 8, offset: 2 }}>
          <h1>Privacy Policy for StockzBot.com</h1>
          <p><em>Last Updated: 9/20/2023</em></p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Introduction</h2>
          <p>
            Welcome to StockzBot.com. We are committed to safeguarding the privacy of our website visitors and service users. This policy sets out how we will treat your personal information when you visit our site.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Data We Collect</h2>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>Personal Data</strong>: This includes data you provide to us voluntarily, such as your name and email address when you sign up for our newsletter.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Usage Data</strong>: With the help of third-party tools like Google Analytics, we may collect data about how you browse and interact with our site, which can include your IP address, browsing history, and other details.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Cookies</strong>: We and our third-party partners, like Google Adsense, may use cookies to track and store user preferences, for advertising purposes, and to collect analytics data.
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Why We Collect Your Data</h2>
          <ListGroup variant="flush">
            <ListGroup.Item>To provide and improve our AI analysis and other services.</ListGroup.Item>
            <ListGroup.Item>To send you newsletters if you've signed up for them.</ListGroup.Item>
            <ListGroup.Item>To personalize your experience on our site.</ListGroup.Item>
            <ListGroup.Item>For advertising and analytical purposes.</ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Data Sharing</h2>
          <p>We do not sell or rent your personal data. However, certain third-party tools we utilize (like Google Analytics and Google Adsense) may access this data as a part of their service.</p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Your Rights</h2>
          <ListGroup variant="flush">
            <ListGroup.Item><strong>Access</strong>: You have the right to request access to the personal data we hold about you.</ListGroup.Item>
            <ListGroup.Item><strong>Deletion</strong>: If you unsubscribe from our newsletter, we will delete the personal data associated with your subscription.</ListGroup.Item>
            <ListGroup.Item><strong>Opt-out</strong>: You can opt-out of our newsletters at any time using the unsubscribe link included in these communications.</ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Geographic Considerations</h2>
          <p>While our primary audience is based in the US, we are dedicated to ensuring the protection of user data from all regions.</p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Changes to the Privacy Policy</h2>
          <p>Any changes to this privacy policy will be posted on our website. We advise you to review it regularly to stay updated.</p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h2>Contact Us</h2>
          <p>For any concerns or questions about this privacy policy or your data, please contact us at info@stockzbot.com.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
