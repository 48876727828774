import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../styles/contactUs.css";

const handleSubmit = async (e) => {
  e.preventDefault();

  const formData = new FormData(e.target);
  const formObject = Object.fromEntries(formData.entries());

  try {
    const response = await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formObject).toString(),
    });

    if (response.ok) {
      // Handle success
      alert("Form submitted successfully!");
      e.target.reset();
    } else if (!response.ok) {
      // Handle errors
      alert("There was an error submitting the form.");
    }
  } catch (error) {
    // Handle exceptions
    console.log("Form submitted"); // This is an error
  }
};

function ContactUs() {
  return (
    <Container className="contact-container">
      <Row>
        <Col md={12}>
          <h2 className="contact-header">Contact Us</h2>
          <p className="contact-paragraph">
            Have questions or feedback? We'd love to hear from you. Fill out the
            form and we'll get back to you as soon as possible.
          </p>

          <Form
            name="contact"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
              />
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
              />
            </Form.Group>

            <Form.Group controlId="subject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="What's this about?"
                name="subject"
              />
            </Form.Group>

            <Form.Group controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Your message here..."
                name="message"
              />
            </Form.Group>

            <Button variant="info" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactUs;
