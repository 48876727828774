import { createClient } from "contentful";
const CONTENTFUL_SPACE = process.env.REACT_APP_SPACE_ID;
const CONTENTFUL_API = process.env.REACT_APP_CONTENTFUL_API;

const client = createClient({
  space: CONTENTFUL_SPACE,
  accessToken: CONTENTFUL_API,
});

export default client;

export const getBlogPosts = async () => {
  try {
    const response = await client.getEntries({ content_type: "pageBlogPost" });
    return response.items;
  } catch (error) {
    console.error("Error fetching blog posts from Contentful", error);
  }
};
