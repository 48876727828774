import React from "react";
import StockTabs from "./stockTab";
import { useStockData } from "./useStockData";
import { useGPT } from "./useGPT";
import { Image, Row, Col, Spinner, Alert } from "react-bootstrap";

const StockWindow = ({ symbol }) => {
  const {
    loading: stockLoading,
    data: stockData,
    error: stockError,
  } = useStockData(symbol);

  const constructPrompt = (data) => {
    if (!data) return null;
    let metricsString = "";
    for (const [key, value] of Object.entries(data)) {
      metricsString += `- ${key}: ${value}\n`;
    }
    return `Based on these ${metricsString} statistics, return a number from 1 to 10. 1 indicates that the stock should never be invested in, and 10 indicates that the stock should be invested in right now! Then explain your analysis in 750 characters or less. Format the response so that it goes Rating:, Analysis:, and Note:, The note should say; This is a basic assessment, and real investment decisions should involve a more comprehensive analysis and professional consultation. Take your time to work out your own solution`;
  };

  const prompt = stockData && !stockLoading ? constructPrompt(stockData) : null;
  const {
    loading: gptLoading,
    response: gptResponse,
    error: gptError,
  } = useGPT(prompt);

  const markdownizeResponse = (response) => {
    return response
      ?.replace("Rating", "**Rating**")
      .replace("Analysis", "\n**Analysis**")
      .replace("Note", "\n**Note**");
  };

  const markdownResponse = gptResponse && markdownizeResponse(gptResponse);

  if (stockLoading) {
    return (
      <div className="text-center">
        <Spinner className="mt-5" animation="border" variant="info" />
        <p className="mt-3">Fetching Data..</p>
      </div>
    );
  }

  return (
    <div>
      {stockError && <Alert variant="danger">{stockError}</Alert>}
      {gptError && <Alert variant="danger">{gptError}</Alert>}

      <Row>
        <Col className="mt-5 mb-2 ml-1">
          <Image
            alt={`Logo for ${stockData.companyName || "the company"}`}
            src={stockData.logoUrl}
            fluid
          />
        </Col>
      </Row>
      <StockTabs
        data={stockData}
        response={markdownResponse}
        gptLoading={gptLoading}
      />
    </div>
  );
};

export default StockWindow;
