import React from "react";
import AdSense from "react-adsense";
import SearchForm from "../components/searchForm";
import BlogArticles from "../components/mainBlogArticles";

export default function Home() {
  return (
    <div>
      <SearchForm />
      <BlogArticles />
      <AdSense.Google
        client="ca-pub-2133956551829595"
        slot="5335049083"
        style={{ display: "block" }}
        format="auto"
        responsive="true"
        layoutKey="-gw-1+2a-9x+5c"
      />
    </div>
  );
}
